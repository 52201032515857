<template>
  <q-layout view="hHh lpR fFf" class="fn-layout">
    <q-header class="hy-header q-px-sm flex flex-center" >
      <q-toolbar>
        <q-toolbar-title>
          <img :src="$q.dark.isActive ? '/images/logo_horizontal--dark.png' : '/images/logo_horizontal--light.png'"
            style="height: 2rem; max-width: 100%; position: relative; margin-top: 5px" />
        </q-toolbar-title>
        <hy-nav-items class="gt-md"/>
        <q-btn flat dense :label="$i18n.locale === 'de' ? 'DE' : 'EN'" @click="toggleLang()" class="q-ml-md hy-nav gt-md"
          style="">
        </q-btn>
        <q-btn flat dense :icon="$q.dark.isActive ? 'dark_mode' : 'light_mode'" class="q-ml-md hy-nav gt-md"
          @click="toggleMode()" style="font-size: 14px" />
        <q-btn v-if="authStore.isAuthenticated" flat dense icon="logout" @click="logout()" class="q-ml-md hy-nav gt-md" style="font-size: 14px" />
        <q-btn dense flat round icon="menu" @click="rightDrawerOpen = !rightDrawerOpen" class="lt-lg q-ml-md hy-nav"
          style="font-size: 14px" />
      </q-toolbar>
    </q-header>
    <q-drawer v-model="rightDrawerOpen" side="right" behavior="mobile" elevated class="text-center">
      <div class="text-center q-py-md">
        <q-btn flat dense :label="$i18n.locale === 'de' ? 'DE' : 'EN'" @click="toggleLang()" class="q-ml-md hy-nav"
          style="width:32px; font-size: 16px">
        </q-btn>
        <q-btn flat dense :icon="$q.dark.isActive ? 'dark_mode' : 'light_mode'" class="q-ml-md hy-nav"
          @click="toggleMode()" style="font-size: 14px" />
      </div>
      <hy-nav-items />
      <q-btn class="q-mt-lg" color="hy-primary" v-if="authStore.isAuthenticated" @click="logout()" :label="$t('app.logout')" />
    </q-drawer>
    <q-page-container class="">
      <router-view />
      <!-- <q-page-sticky position="right" :offset="[-55, 0]">
        <q-btn color="hy-primary" icon="chat" :label="$t('app.feedback')" class="rotate-270" @click="feedback()">
        </q-btn>
      </q-page-sticky> -->
    </q-page-container>
  </q-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import HyNavItems from '@/components/Navigation.vue'
import { useRootStore } from './stores/root'
import { useAuthStore } from './stores/auth'
import { useDataStore } from './stores/data'

export default defineComponent({
  // mixins: [FnStoreMixin],
  components: {
    HyNavItems
  },
  data() {
    return {
      rightDrawerOpen: false,
      language: 'de',
      rootStore: useRootStore(),
      authStore: useAuthStore(),
      dataStore: useDataStore()
    }
  },
  methods: {
    toggleLang() {
      const cur = window.localStorage.getItem('hyoban.language')

      if (cur === 'en') {
        this.$i18n.locale = this.language = 'de'
        window.localStorage.setItem('hyoban.language', 'de')
      } else {
        this.$i18n.locale = this.language = 'en'
        window.localStorage.setItem('hyoban.language', 'en')
      }
    },
    toggleMode() {
      if (this.$q.dark.isActive) {
        window.localStorage.setItem('hyoban.dark', 'off')
        this.$q.dark.set(false)
      } else {
        window.localStorage.setItem('hyoban.dark', 'on')
        this.$q.dark.set(true)
      }
    },
    logout() {
      this.authStore.logout()
      this.$router.push('/login')
    },
    setLanguage(lang: 'en' | 'de' | 'fr') {
      this.$i18n.locale = this.language = lang
      window.localStorage.setItem('hyoban.language', lang)
    },
    feedback() {
      this.$q.dialog({
        
        title: this.$t('app.feedback'),
        class: 'hy-dialog',
        message: this.$t('app.feedbackDescription'),
        prompt: {
          model: '',
          placeholder: this.$t('app.feedbackPlaceholder'),
          type: 'textarea',
          filled: true,
          color: 'hy-primary'
        },
        color: 'hy-primary',
        cancel: this.$t('app.cancel'),
        ok: this.$t('app.submit'),
        persistent: false
      }).onOk(async message => {
        const sent = await this.dataStore.submitFeedback(
          message,
          //@ts-ignore
          window.smartlook.sessionId,
          this.rootStore.activeReview
        )

        if (sent) {
          this.dataStore.addToastMessage({
            severity: "positive",
            caption: this.$t("app.feedbackSent"),
            message: this.$t("app.success"),
          })
        }else {
          this.dataStore.addToastMessage({
            severity: "negative",
            caption: this.$t("app.feedbackError"),
            message: this.$t("app.error"),
          })

        }

      })
    }
  },
  watch: {
    'select': {
      handler() {
        console.log('select')
      }
    },
    'rootStore.toastMessages': {
      deep: true,
      handler() {
        if (!this.rootStore.toastMessages.length) {
          return;
        }

        const message = this.rootStore.popToastMessage();

        let icon = 'mdi-information-outline'

        switch (message?.severity) {
          case 'negative':
            icon = 'error_outline'
            break;
          case 'warning':
            icon = 'warning_amber'
            break;
          case 'positive':
            icon = 'check_circle_outline'
            break;
          default:
            icon = 'info_outline'
            break;
        }

        this.$q.notify({
          message: message?.message,
          position: 'top-right',
          progress: true,
          icon: icon,
          iconColor: message?.severity,
          caption: message?.caption,
          classes: `fn-surface shadow-4 fn-toast ${message?.severity}`,
          timeout: message?.severity === 'negative' ? 0 : 5000,
          closeBtn: false,
          badgeColor: this.$q.dark.isActive ? 'white' : 'black',
          badgeTextColor: this.$q.dark.isActive ? 'black' : 'white',
          group: false,
          actions: [
            {
              icon: 'close',
              color: this.$q.dark.isActive ? 'black' : 'white',
            },
          ],
        })

      }
    }
  },
  created() {
    // for initial users the values are set in @/pligins/browser.ts
    this.$i18n.locale = this.language = window.localStorage.getItem('hyoban.language') as 'de' | 'en'
    this.$q.dark.set(window.localStorage.getItem('hyoban.dark') === 'on')

    useAuthStore().init()
  },
})
</script>